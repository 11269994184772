import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthModel } from '../authentication/models/auth.model';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiUrl;
  }

  private getAuthFromLocalStorage(): AuthModel | undefined {
    try {
      const lsValue = localStorage.getItem('token');
      if (!lsValue) {
        return undefined;
      }
      const authData = JSON.parse(lsValue);
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  createAuthorizationHeader() {
    let headers: HttpHeaders = new HttpHeaders();
    const auth = this.getAuthFromLocalStorage();
    headers = headers.append('Authorization', 'Bearer ' + auth);
    return headers;
  }

  createQueryString(paramData: any) {
    let queryString = '?';
    for (const [key, value] of Object.entries(paramData)) {
      if (value != null || value != undefined) {
        queryString += `${key}=${encodeURIComponent(String(value))}&`;
      }
    }
    // Remove the trailing '&' character from the query string
    return queryString = queryString.slice(0, -1);
  }

  get(url: string, data?: any) {
    const headers = this.createAuthorizationHeader();
    const apiUrl = `${this.baseUrl}/${url}`;

    if (data) {
      return this.http.get<any>(`${apiUrl}${this.createQueryString(data)}`, { headers: headers });
    } else {
      return this.http.get<any>(apiUrl, { headers: headers });
    }
  }

  post(url: string, data: any) {
    const headers = this.createAuthorizationHeader();
    return this.http.post<any>(`${this.baseUrl}/${url}`, data, {
      headers: headers,
    });
  }

  put(url: string, id: number, data: any) {
    const headers = this.createAuthorizationHeader();
    return this.http.put<any>(`${this.baseUrl}/${url}/${Number(id)}`, data, {
      headers: headers,
    });
  }

  delete(url: string, id: number) {
    const headers = this.createAuthorizationHeader();
    return this.http.delete<any>(`${this.baseUrl}/${url}/${Number(id)}`, {
      headers: headers,
    });
  }

  getInitials(name) {
    var canvas = document.createElement("canvas");
    canvas.style.display = "none";
    canvas.width = 35;
    canvas.height = 35;
    document.body.appendChild(canvas);
    var context = canvas.getContext("2d");
    context.fillStyle = "#000000";
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.font = "20px Arial";
    context.fillStyle = "#FFFFFF";
    var nameArray = name.split(" ");
    var initials = "";
    initials = initials + nameArray[0][0];
    initials = initials == "undefined" ? "U" : initials;
    if (initials.length > 1) {
      context.fillText(initials.toUpperCase(), 11, 24);
    } else {
      context.fillText(initials.toUpperCase(), 11, 24);
    }
    var data = canvas.toDataURL();
    document.body.removeChild(canvas);
    return data;
  }

  back() {
    window.history.back();
  }

  getDate(date: any) {
    const year = date.getFullYear();
    const month = this.padZero(date.getMonth() + 1); // Month is zero-based, so we add 1
    const day = this.padZero(date.getDate());

    return `${year}-${month}-${day}`;
  }

  padZero(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }

  onlyNumber(event: any) {
    var charCode = event.keyCode;
    if ((charCode < 48 || charCode > 57) && charCode != 46) {
      event.preventDefault();
    }
  }
}
