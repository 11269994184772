import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthModel } from '../../authentication/models/auth.model';
import { LoginModel } from '../../authentication/models/LoginModel';
import { AuthHTTPService } from './login.service';
import { LocalStorageService } from '../local-storage.service';

export type UserType = LoginModel | undefined;

@Injectable({
    providedIn: 'root',
})
export class AuthService implements OnDestroy {
    // private fields
    private unsubscribe: Subscription[] = [];
    // public fields
    currentUser$: Observable<UserType>;
    isLoading$: Observable<boolean>;
    currentUserSubject: BehaviorSubject<UserType>;
    isLoadingSubject: BehaviorSubject<boolean>;

    get currentUserValue(): UserType {
        return this.currentUserSubject.value;
    }

    set currentUserValue(user: UserType) {
        this.currentUserSubject.next(user);
    }

    loginError: any;
    constructor(
        private authHttpService: AuthHTTPService,
        private router: Router,
        private localStorageService: LocalStorageService
    ) {
        this.isLoadingSubject = new BehaviorSubject<boolean>(false);
        this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
        this.currentUser$ = this.currentUserSubject.asObservable();
        this.isLoading$ = this.isLoadingSubject.asObservable();
    }

    login(email: string, password: string): Observable<UserType> {
        this.isLoadingSubject.next(true);
        return this.authHttpService.login(email ? email : '', password ? password : '', 1).pipe(
            map((auth: AuthModel) => {
                let authData: any = auth
                if(authData.data.user?.role?.type != "authenticated"){
                    this.loginError = "An error occurred during login.";
                    return of(undefined);
                }
                this.currentUserSubject.next(authData.data.user)
                const result = this.setAuthFromLocalStorage(authData.data);
                return result;
            }),

            catchError((err) => {
                this.loginError = err;
                return of(undefined);
            }),
            finalize(() => this.isLoadingSubject.next(false))
        );
    }

    //Return appropriate error messeage.
    getErrorMsg() {
        if (this.loginError?.error?.error?.details?.errors) {
            return this.loginError?.error?.error?.details?.errors[0]?.message ? this.loginError?.error?.error?.details?.errors[0]?.message : null;
        } else {
            return this.loginError.error.error.message ? this.loginError.error.error.message : null;
        }
    }

    logout() {
        localStorage.clear();
        this.localStorageService.clearToken();
        this.router.navigate(['/auth/login']);
    }


    forgotPassword(email: string): Observable<boolean> {
        this.isLoadingSubject.next(true);
        return this.authHttpService
            .forgotPassword(email)
            .pipe(finalize(() => this.isLoadingSubject.next(false)));
    }

    // resetPassword(password: string, passwordConfirmation: string, code: string): Observable<any> {
    //     this.isLoadingSubject.next(true);
    //     return this.authHttpService
    //         .resetPassword(password, passwordConfirmation, code)
    //         .pipe(finalize(() => this.isLoadingSubject.next(false)));
    // }

    resetPassword(password: string, passwordConfirmation: string, code: string): Observable<any> {
        this.isLoadingSubject.next(true);
        return this.authHttpService.resetPassword(password, passwordConfirmation, code).pipe(
            map((response: any) => {
                return response;
            }),

            catchError((err) => {
                return of(undefined);
            }),
            finalize(() => this.isLoadingSubject.next(false))
        );
    }

    // private methods
    private setAuthFromLocalStorage(auth: AuthModel): boolean {
        if (auth && auth.jwt) {
            this.localStorageService.setItem('token', JSON.stringify(auth.jwt));
            this.localStorageService.setItem('user', JSON.stringify(auth.user));
            return true;
        }
        return false;
    }

    ngOnDestroy() {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }
}
