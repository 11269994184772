import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthModel } from '../../authentication/models/auth.model';

const API_USERS_URL = `${environment.apiUrl}/auth`;

@Injectable({
  providedIn: 'root',
})

export class AuthHTTPService {
  constructor(private http: HttpClient) { }

  // public methods
  login(identifier: string, password: string, role: Number): Observable<any> {
    return this.http.post<AuthModel>(`${API_USERS_URL}/local`, {
      identifier,
      password,
      role
    });
  }

  forgotPassword(email: string): Observable<boolean> {
    return this.http.post<boolean>(`${API_USERS_URL}/forgot-password`, {
      email,
    });
  }

  resetPassword(password: string, passwordConfirmation: string, code: string): Observable<boolean> {
    return this.http.post<boolean>(`${API_USERS_URL}/reset-password`, {
      password,
      passwordConfirmation,
      code
    });
  }
}
