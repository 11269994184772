import { Injectable } from '@angular/core';
import {
    CanActivate,
} from '@angular/router';
import { AuthService } from '../authentication/auth.service';
import { LocalStorageService } from '../local-storage.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService, private localStorageService: LocalStorageService) { }

    canActivate() {
        const currentUser = this.authService.currentUserValue;

        if (this.localStorageService.getItem('token') == null) {
            this.authService.logout();
            this.localStorageService.clearToken();
            return false;
        } else {
            return true;
        }
    }
}