import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { CryptoService } from './crypto.service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private localStorageSubject = new BehaviorSubject<string | null>(null);

  constructor(private cryptoService: CryptoService) {
    this.localStorageSubject.next(localStorage.getItem('user')); // Initial value
  }

  setItem(key: string, value: string) {
    localStorage.setItem(key, value);
    this.localStorageSubject.next(value); // Notify subscribers about the change
  }

  getItem(key: string): string | null {
    return localStorage.getItem(key);
  }

  getLocalStorageObservable(): Observable<string | null> {
    return this.localStorageSubject.asObservable();
  }

  // Set the json data to local storage
  setJsonValue(key: string, value: any) {
    this.cryptoService.secureStorage.setItem(key, value);
  }
  // Get the json value from local storage
  getJsonValue(key: string) {
    return this.cryptoService.secureStorage.getItem(key);
  }
  // Clear the local storage
  clearToken(key?: string) {
    if (key) {
      return this.cryptoService.secureStorage.clear(key);
    } else {
      return this.cryptoService.secureStorage.clear();
    }
  }
}
